html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  font-size: 16px;
  height: 100%;
  width: 100%;
}

:root {
  --primary-color: #1A212E;
  --text-color: #A7C8E2;
}

body {
  background-color: var(--primary-color);
  color: var(--text-color);
  position: relative;
  z-index: 1;
}

body::after {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  inset: 0;
  background: url('/bg.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: .05;
  z-index: -1;
}

#__next,
#__next>div {
  width: 100%;
  height: 100%;
}

#__next>div {
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

i {
  font-style: normal;
}

a.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-color);
  color: var(--primary-color);
  border-radius: 50%;
  overflow: hidden;
  font-size: 1rem;
  width: 30px;
  height: 30px;
  opacity: .5;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

a.icon:hover {
  opacity: 1;
  transform: scale(1.15);
}

.container {
  width: 100%;
  padding: 5px 20px;
  max-width: 1170px;
  margin: auto;
}